import React from "react";

import { Link, SettingsPageContainer, useToast } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CurrentUserFragment } from "../../graphql";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import BrowserExtensionSettingsForm, {
  FormData,
} from "./BrowserExtensionSettingsForm";

interface BrowserExtensionProps {
  currentUser: CurrentUserFragment;
}

const BrowserExtensionSettings: React.FC<BrowserExtensionProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  const [updateCurrentUser, { loading: updateLoading, error }] =
    useUpdateCurrentUser({
      onCompleted: (user) => {
        if (user) {
          sendGAEvent("updated", "user_settings");
          toast({
            title: "Settings saved",
            status: "success",
          });
        }
      },
      onError: () => {
        toast({
          title: "Error updating user",
          status: "error",
        });
      },
    });

  const onSubmit = (formData: FormData): void => {
    updateCurrentUser({
      variables: formData,
    });
  };

  return (
    <SettingsPageContainer
      heading="Browser Extension"
      subHeading={
        <>
          Use the BrightHire browser extension to use BrightHire in your ATS and
          easily add BrightHire to your meetings.{" "}
          <Link
            href="https://chromewebstore.google.com/detail/brighthire/mbokiighkhdopgedihndpibkincpcgan?hl=en-US"
            target="_blank"
          >
            Install here
          </Link>
        </>
      }
    >
      <BrowserExtensionSettingsForm
        error={error}
        isLoading={updateLoading}
        onSubmit={onSubmit}
        showJoinWithBrighthire={currentUser.showJoinWithBrighthire ?? ""}
      />
    </SettingsPageContainer>
  );
};

export default BrowserExtensionSettings;
