import { useTheme } from "@chakra-ui/react";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { Alert, LoadingIndicator } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker } from "../../../utils/googleAnalytics";
import CustomTemplate from "../../components/CallTemplates/CustomTemplate";
import { useAiSummaryTemplateQuery } from "../../graphql";
import { DefaultPageLayout } from "../../layouts";
import Forbidden from "../forbidden/Forbidden";

const TemplatePage: React.FC = () => {
  usePageTracker("custom_template");
  const { percentHeight } = useWindowDimensions();
  const theme = useTheme();
  const { pagePadding } = theme.sizes;

  const { templateId } = useParams() as { templateId: string };
  const { location } = window;
  const callId = new URLSearchParams(location.search).get("callId");

  const { loading, error, data } = useAiSummaryTemplateQuery({
    variables: { id: templateId },
    skip: !templateId,
  });

  if (loading) {
    return (
      <DefaultPageLayout>
        <LoadingIndicator mt={percentHeight(25)} />
      </DefaultPageLayout>
    );
  }

  if (error) {
    if (error.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN")) {
      return (
        <Forbidden forbiddenFrom="template">
          You can request access from a site administrator.
        </Forbidden>
      );
    }
    return <Alert status="error" description="Error loading template" reload />;
  }

  if (templateId && !data?.aiSummaryTemplate) {
    return <Navigate to="/not-found" />;
  }

  return (
    <DefaultPageLayout p={pagePadding} pt={[0, 0, 0, 0]} pb={[0, 0, 0, 0]}>
      <CustomTemplate
        template={data?.aiSummaryTemplate || null}
        fromCallId={callId}
      />
    </DefaultPageLayout>
  );
};

export default TemplatePage;
