import React from "react";
import { HiOutlinePlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../components";

const CreateTemplateButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      ml={4}
      variant="prismPrimary"
      size="prismMd"
      leftIcon={<HiOutlinePlus />}
      onClick={() => {
        navigate("/template/new");
      }}
    >
      New template
    </Button>
  );
};

export default CreateTemplateButton;
