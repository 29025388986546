import {
  Box,
  Checkbox,
  Code,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  SettingsPageContainer,
  StatusWithText,
  useToast,
} from "../../../../components";
import {
  CurrentUserFragment,
  GreenhouseSetupDocument,
  useGenerateGreenhouseWebhookSecretMutation,
  useGreenhouseSetupQuery,
  useSetupGreenhouseMutation,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { ScorecardAutoDraftSettings } from "./ScorecardAutoDraftSettings";

interface GreenhouseSettingsProps {
  currentUser: CurrentUserFragment;
}

interface FormValues {
  enabled: boolean;
  apiKey: string;
  host: string;
  adminUserId: string;
  syncData: boolean;
  syncCallGuidesFromAts: boolean;
}

const GreenhouseSettings: React.FC<GreenhouseSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const { data, loading } = useGreenhouseSetupQuery({
    variables: { verifyAdminUserId: true },
  });
  const greenhouseSetup = data?.greenhouseSetup;

  const [setupGreenhouse, { loading: setupGreenhouseLoading }] =
    useSetupGreenhouseMutation({
      update: (cache, { data }) => {
        if (data?.setupGreenhouse?.setup) {
          cache.writeQuery({
            query: GreenhouseSetupDocument,
            data: {
              greenhouseSetup: data.setupGreenhouse.setup,
            },
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to setup Greenhouse: ${err.message}`,
          status: "error",
        });
      },
    });

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      enabled: false,
      apiKey: "",
      host: "app.greenhouse.io",
      syncCallGuidesFromAts: false,
    },
  });
  const watchValues = watch();

  const onSubmit = handleSubmit((formValues) => {
    let apiKey;
    if (!formValues.apiKey.includes("XXXX")) {
      apiKey = formValues.apiKey;
    }
    setupGreenhouse({
      variables: {
        enabled: formValues.enabled,
        apiKey,
        host: formValues.host,
        adminUserId: parseInt(formValues.adminUserId),
        syncCallGuidesFromAts: formValues.syncCallGuidesFromAts,
      },
    });
  });

  useEffect(() => {
    if (greenhouseSetup) {
      reset({
        enabled: greenhouseSetup.enabled,
        apiKey: greenhouseSetup.apiKey,
        host: greenhouseSetup.host,
        adminUserId: greenhouseSetup.adminUserId.toString(),
        syncCallGuidesFromAts: greenhouseSetup.syncCallGuidesFromAts,
      });
    }
  }, [greenhouseSetup, reset]);

  const [generateSecret, { loading: generateSecretLoading }] =
    useGenerateGreenhouseWebhookSecretMutation({
      update(cache, { data: mutationData }) {
        const webhookSecretKey =
          mutationData?.generateGreenhouseWebhookSecret?.webhookSecretKey;
        if (greenhouseSetup && webhookSecretKey) {
          cache.writeQuery({
            query: GreenhouseSetupDocument,
            data: {
              greenhouseSetup: { ...greenhouseSetup, webhookSecretKey },
            },
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to generate Greenhouse Webhook Secret: ${err.message}`,
          status: "error",
        });
      },
    });

  const isLoading = loading || setupGreenhouseLoading;

  const scorecardAutoDraftVisible = useFeatureFlag("greenhouse:v3-api");

  return (
    <SettingsPageContainer heading="Greenhouse">
      <Heading as="h3" fontSize="md" mb={2}>
        Integration Status
      </Heading>
      <Box mb={5}>
        {isLoading ? (
          <Spinner />
        ) : (
          <StatusWithText
            passed={greenhouseSetup?.valid ?? false}
            label={greenhouseSetup?.invalidReason ?? ""}
          />
        )}
      </Box>
      <form onSubmit={onSubmit}>
        <Flex direction="column" gap="4" align="start">
          <FormControl id="enabled" maxW="400">
            <Checkbox
              {...register("enabled")}
              isChecked={watchValues.enabled}
              onChange={(e) => {
                setValue("enabled", e.target.checked);
              }}
            >
              Enabled
            </Checkbox>
          </FormControl>
          <FormControl id="syncCallGuidesFromAts" maxW="400" mt="2">
            <Checkbox
              {...register("syncCallGuidesFromAts")}
              isChecked={watchValues.syncCallGuidesFromAts}
              onChange={(e) => {
                setValue("syncCallGuidesFromAts", e.target.checked);
              }}
            >
              Sync Guides
            </Checkbox>
          </FormControl>
          <FormControl id="apiKey" isRequired maxW="400">
            <FormLabel>API Key</FormLabel>
            <Input {...register("apiKey")} type="password" />
          </FormControl>
          <FormControl id="host" isInvalid={!!errors?.host} maxW="400">
            <FormLabel>Host</FormLabel>
            <Input
              placeholder="app.greenhouse.io"
              {...register("host", {
                onBlur: () => trigger("host"),
                validate: {
                  http: (value) => value.match(/^http/) === null,
                  slash: (value) => value.match(/\//g) === null,
                  other: (value) => value.match(/^[0-9a-z-.]+$/g) !== null,
                },
              })}
            />
            <FormErrorMessage>
              {errors.host?.type === "http" && (
                <>
                  Remove the&nbsp;<Code>http</Code>&nbsp;
                </>
              )}
              {errors.host?.type === "slash" && (
                <>
                  Remove the&nbsp;<Code>/</Code>
                </>
              )}
              {errors.host?.type === "other" && (
                <>Only include letters, numbers, and hyphens</>
              )}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="adminUserId"
            isRequired
            isInvalid={!!errors?.adminUserId}
            maxW="400"
          >
            <FormLabel>Admin User ID</FormLabel>
            <Input
              {...register("adminUserId", {
                pattern: {
                  value: /^\s*\d+\s*$/,
                  message: "Only numbers are allowed",
                },
              })}
            />
            {errors.adminUserId && (
              <FormErrorMessage>{errors.adminUserId.message}</FormErrorMessage>
            )}
            <FormHelperText fontSize="xs" color="gray.500" mt="1">
              The ID of the Greenhouse user that will be used to post notes to
              the activity feed, e.g. &ldquo;12345678&rdquo;.
            </FormHelperText>
          </FormControl>
          <Button type="submit" disabled={isLoading} mt="2">
            Save
          </Button>
        </Flex>
      </form>
      {greenhouseSetup?.valid && (
        <>
          <Divider my="5" />
          <Stack mt={4} spacing={2}>
            <Text fontWeight="semibold">Webhook Secret</Text>
            <Text>
              The webhook secret is generated by BrightHire to use with
              Greenhouse.
            </Text>
            <HStack spacing={2}>
              <Input
                readOnly
                placeholder="No secret generated."
                value={greenhouseSetup?.webhookSecretKey || ""}
                width={450}
              />
              <Button
                onClick={() => {
                  generateSecret({
                    variables: {},
                  });
                }}
                disabled={generateSecretLoading}
              >
                Generate
              </Button>
            </HStack>
          </Stack>
          <Stack mt={4} spacing={2}>
            <Text fontWeight="semibold">Webhook Url</Text>
            <Text>
              Please use this url when setting up webhooks in Greenhouse.
            </Text>
            <Input
              readOnly
              value={`https://app.brighthire.ai/callback/greenhouse/webhook/${currentUser.organization.id}`}
            />
          </Stack>
        </>
      )}
      {isLoading && (
        <>
          <Divider my="6" />
          <Spinner />
        </>
      )}
      {scorecardAutoDraftVisible && <ScorecardAutoDraftSettings />}
    </SettingsPageContainer>
  );
};

export default GreenhouseSettings;
