import {
  CurrentUserFragment as CurrentInterviewUserFragment,
  UserRoleName,
  UserRolePermission,
} from "../main/graphql";
import { CurrentPlanUserFragment } from "../plan/graphql";

type CurrentUserFragment =
  | CurrentInterviewUserFragment
  | CurrentPlanUserFragment;

export const canManageTraining = (
  user: CurrentInterviewUserFragment | CurrentPlanUserFragment
): boolean => {
  if (user.userRole?.canCreateTrainings) return true;

  const membershipWithPermission = user.memberships.find((m) => {
    return m.role.canCreateTrainings;
  });

  return membershipWithPermission !== undefined;
};

export const canDownloadRecording = (user: CurrentUserFragment): boolean => {
  const hasPermission = !!user.userRole?.permissions?.includes(
    UserRolePermission.DownloadRecording
  );
  return hasPermission;
};

export const canViewOrgInsights = (user: CurrentUserFragment): boolean => {
  const isSiteAdmin = user.userRole?.name === UserRoleName.SiteAdmin;
  return isSiteAdmin;
};

export const canViewAdvancedInsights = (user: CurrentUserFragment): boolean => {
  const isSiteAdmin = user.userRole?.name === UserRoleName.SiteAdmin;
  return isSiteAdmin;
};

export const canViewIQReport = (user: CurrentUserFragment): boolean => {
  const isSiteAdmin = user.userRole?.name === UserRoleName.SiteAdmin;
  const hasPermission = !!user.userRole?.permissions?.includes(
    UserRolePermission.ViewInterviewerQualityReport
  );

  return isSiteAdmin || hasPermission;
};

export const canViewMyInsightsForInterviewer = (
  user: CurrentUserFragment,
  interviewerId?: string
): boolean => {
  const isSiteAdmin = user.userRole?.name === UserRoleName.SiteAdmin;
  const currentUserIsInterviewer = user.id === interviewerId;

  return isSiteAdmin || currentUserIsInterviewer;
};
