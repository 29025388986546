import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import {
  CallAiSummaryFormat,
  CustomTemplateCustomType,
  useCreateAiSummaryTemplateMutation,
  useDuplicateAiSummaryTemplateMutation,
  useUpdateAiSummaryTemplateMutation,
  useUpdateCallAiSummaryFormatMutation,
} from "../../graphql";
import { CustomTemplateInput } from "./types";

type CustomTemplateReturn = {
  createCustomTemplate: (input: CustomTemplateInput) => void;
  updateCustomTemplate: (input: CustomTemplateInput) => void;
  duplicateCustomTemplate: (id: string) => void;
  navigateBack: (templateId?: string) => void;
};

type UseCustomTemplateProps = {
  fromCallId: string | null;
};

const useCustomTemplate = (
  props: UseCustomTemplateProps
): CustomTemplateReturn => {
  const { fromCallId } = props;
  const navigate = useNavigate();
  const toast = useToast();

  // Navigate back to the correct page, either call page or templates list
  const navigateBack = useCallback(
    (templateId?: string, regenerate?: boolean) => {
      if (fromCallId && templateId) {
        // Navigate back to the call page with the new or updated template
        // Option to regenerate the summary (for updating existing templates)
        if (regenerate) {
          generateSummary({
            variables: {
              callId: fromCallId,
              format: CallAiSummaryFormat.Custom,
              customTemplateId: templateId,
              targetSpeakerTags: [],
              customTopics: [],
              forceRegenerate: true,
            },
          });
        }
        navigate(
          `/interview/${fromCallId}?tab=ai_notes&customTemplateId=${templateId}`
        );
      } else if (fromCallId) {
        // Navigate back to the previous URL (for cancelling creation from call page)
        navigate(-1);
      } else {
        // Navigate back to the templates list
        navigate("/templates");
      }
    },
    [fromCallId, navigate]
  );

  // Create a new template
  const [createTemplateMutation] = useCreateAiSummaryTemplateMutation();
  const createCustomTemplate = (input: CustomTemplateInput): void => {
    const sectionsWithoutIds = input.sections.map((section) => ({
      title: section.title,
      prompt: section.prompt,
    }));
    createTemplateMutation({
      variables: {
        name: input.name,
        isCreatorDefault: input.isCreatorDefault,
        sections: sectionsWithoutIds,
        customType: input.customType || CustomTemplateCustomType.Sections,
        customPrompt: input.customPrompt,
        model: input.model,
        visibility: input.visibility,
        isCurrentUserDefault: input.isCurrentUserDefault,
        defaultFor: input.defaultFor,
        defaultForPersona: input.defaultForPersona,
        defaultForKeywords: input.defaultForKeywords,
        defaultForMeetingType: input.defaultForMeetingType,
      },
      onCompleted: (data) => {
        const newTemplateId = data.createAiSummaryTemplate?.template?.id;
        if (newTemplateId) {
          toast({
            status: "success",
            description: "Template created",
            position: "top",
          });
          navigateBack(newTemplateId, true);
        }
      },
      onError: (error) => {
        toast({
          status: "error",
          description: "Failed to create template. Please try again.",
          position: "top",
        });
      },
    });
  };

  // Update an existing template
  const [updateTemplateMutation] = useUpdateAiSummaryTemplateMutation();
  const updateCustomTemplate = (input: CustomTemplateInput): void => {
    const sectionsWithoutIds = input.sections.map((section) => ({
      title: section.title,
      prompt: section.prompt,
    }));
    if (!input.id) return;
    updateTemplateMutation({
      variables: {
        id: input.id,
        name: input.name,
        isCreatorDefault: input.isCreatorDefault,
        sections: sectionsWithoutIds,
        customType: input.customType || CustomTemplateCustomType.Sections,
        customPrompt: input.customPrompt,
        model: input.model,
        visibility: input.visibility,
        isCurrentUserDefault: input.isCurrentUserDefault,
        defaultFor: input.defaultFor,
        defaultForPersona: input.defaultForPersona,
        defaultForKeywords: input.defaultForKeywords,
        defaultForMeetingType: input.defaultForMeetingType,
      },
      onCompleted: (data) => {
        const templateId = data.updateAiSummaryTemplate?.template?.id;
        if (templateId) {
          toast({
            status: "success",
            description: "Template updated",
            position: "top",
          });
          navigateBack(templateId, true);
        }
      },
      onError: (error) => {
        toast({
          status: "error",
          description: "Failed to update template. Please try again.",
          position: "top",
        });
      },
    });
  };

  // Copy a template
  const [duplicateTemplateMutation] = useDuplicateAiSummaryTemplateMutation();
  const duplicateCustomTemplate = (id: string): void => {
    duplicateTemplateMutation({
      variables: {
        id,
      },
      onCompleted: (data) => {
        const templateId = data.duplicateAiSummaryTemplate?.template?.id;
        if (templateId) {
          toast({
            title: "Template copied to My Templates",
            status: "success",
          });
          navigateBack(templateId);
        }
      },
      onError: (error) => {
        toast({
          status: "error",
          description: "Failed to copy template. Please try again.",
          position: "top",
        });
      },
    });
  };

  const [generateSummary] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  return {
    createCustomTemplate,
    updateCustomTemplate,
    duplicateCustomTemplate,
    navigateBack,
  };
};

export default useCustomTemplate;
