import {
  Box,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import {
  HiDotsVertical,
  HiOutlineDuplicate,
  HiOutlineInformationCircle,
} from "react-icons/hi";

import {
  Button,
  FullscreenHeader,
  IconButton,
  MenuButton,
} from "../../../components";
import {
  CallAiSummaryTemplateDefaultFor,
  CallAiSummaryTemplateFragment,
  CallAiSummaryTemplateVisibility,
  CustomTemplateCustomType,
  CustomTemplateType,
  UserPersona,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import DeleteTemplateMenuItem from "../CallAiSummaryTemplateList/DeleteTemplateMenuItem";
import SectionsForm from "./SectionsForm";
import { CustomTemplateInput, CustomTemplateSectionInput } from "./types";

const pageHeaderHeight = "68px";

type EditCustomTemplateProps = {
  template: CallAiSummaryTemplateFragment | null;
  onClose: () => void;
  onSave: (input: CustomTemplateInput) => void;
  onDelete?: (id: string) => void;
  onCopy?: (id: string) => void;
};

const EditCustomTemplate: React.FC<EditCustomTemplateProps> = ({
  template,
  onClose,
  onDelete,
  onSave,
  onCopy,
}) => {
  const currentUser = useCurrentUser();
  const currentUserCanManageTemplates =
    currentUser.userRole?.canManageCustomTemplates;

  // If template is null, user is creating a new template
  // If template is not null, user is editing an existing template
  const canManageVisibility = !template
    ? currentUserCanManageTemplates
    : template.visibility === CallAiSummaryTemplateVisibility.Public ||
      template.canMakePublic;

  const [customType, setCustomType] = useState<
    CustomTemplateCustomType | undefined
  >(template?.customType || CustomTemplateCustomType.Sections);
  const [customPrompt, setCustomPrompt] = useState(
    template?.customPrompt || ""
  );
  const [templateName, setTemplateName] = useState<string>(
    template?.name || ""
  );
  const [sections, setSections] = useState<CustomTemplateSectionInput[]>(
    template?.sections || []
  );
  const [visibility, setVisibility] = useState<CallAiSummaryTemplateVisibility>(
    template?.visibility || CallAiSummaryTemplateVisibility.Private
  );
  const [isCurrentUserDefault, setIsCurrentUserDefault] = useState<boolean>(
    template?.isCurrentUserDefault || false
  );
  const [defaultFor, setDefaultFor] = useState<CallAiSummaryTemplateDefaultFor>(
    template?.defaultFor || CallAiSummaryTemplateDefaultFor.None
  );
  const [defaultForPersona, setDefaultForPersona] = useState<
    UserPersona | undefined
  >(template?.defaultForPersona || undefined);
  const [defaultForKeywords, setDefaultForKeywords] = useState<string>(
    (template?.defaultForKeywords || []).join(", ")
  );
  const [defaultForMeetingType, setDefaultForMeetingType] =
    useState<CustomTemplateType>(
      template?.defaultForMeetingType || CustomTemplateType.Interview
    );

  const handleMove = useCallback(
    (fromIndex: number, toIdx: number): void => {
      if (!sections) {
        return;
      }
      const item = sections[fromIndex];
      if (!item) return;
      if (toIdx >= sections.length) return;

      let toIndex = toIdx;
      const movingDown = toIndex >= fromIndex;
      if (movingDown) toIndex += 1;

      const newList = [] as Array<CustomTemplateSectionInput>;
      for (let i = 0; i < sections.length; i += 1) {
        // eslint-disable-next-line
        if (i === fromIndex) continue;
        if (i === toIndex) {
          newList.push(sections[fromIndex]);
        }
        newList.push(sections[i]);
      }
      if (toIndex === sections.length) {
        newList.push(sections[fromIndex]);
      }
      setSections(newList);
    },
    [sections]
  );

  const onAddSection = useCallback(() => {
    const id = Math.random().toString(36).substr(2, 9);
    setSections((prev) => [...prev, { id, title: "", prompt: [""] }]);
  }, []);

  const onDeleteSection = useCallback((id: string) => {
    setSections((prev) => prev.filter((section) => section.id !== id));
  }, []);

  const updateSection = (
    index: number,
    title: string,
    prompt: string
  ): void => {
    const newSections = [...sections];
    const promptArr = [prompt];
    newSections[index] = {
      ...newSections[index],
      title,
      prompt: promptArr,
    };
    setSections(newSections);
  };

  const saveTemplate = (): void => {
    onSave({
      id: template?.id,
      name: templateName,
      isCreatorDefault: template?.isCreatorDefault || false,
      sections,
      customType,
      customPrompt,
      visibility,
      isCurrentUserDefault,
      defaultFor,
      defaultForPersona,
      defaultForKeywords: defaultForKeywords.split(",").map((k) => k.trim()),
      defaultForMeetingType,
    });
  };

  return (
    <Box>
      <FullscreenHeader>
        <Flex height="40px" my={3} flexDir="row" alignItems="center">
          <Box fontSize="xl" fontWeight="medium">
            {template ? "Edit" : "New"} AI notes template
          </Box>
          <Button
            ml="auto"
            size="prismMd"
            variant="prismTertiary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            ml={6}
            size="prismMd"
            variant="prismPrimary"
            onClick={saveTemplate}
          >
            Save
          </Button>
          {template?.id && onDelete && onCopy && (
            <Menu variant="ghost">
              <MenuButton
                as={IconButton}
                ml={6}
                icon={<HiDotsVertical />}
                variant="ghost"
                size="sm"
              />
              <MenuList>
                <>
                  <MenuItem
                    value="duplicate"
                    onClick={() => {
                      onCopy(template.id || "");
                      onClose();
                    }}
                    fontSize="sm"
                  >
                    <HiOutlineDuplicate size="20px" />
                    <Box flex="1" ml="2">
                      Duplicate
                    </Box>
                  </MenuItem>
                  <MenuDivider />
                  <DeleteTemplateMenuItem
                    templateId={template.id}
                    onDeleted={() => {
                      onClose();
                    }}
                    fontSize="sm"
                  />
                </>
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Box
          height={1}
          background="linear-gradient(99.01deg, #0074DD -2.93%, #591EFF 58.85%, #9644FF 126.18%)"
        />
      </FullscreenHeader>
      <TemplateFormContent>
        <TemplateFormColumn flex="4" pr={[0, 0, 8, 8]}>
          <Text color="gray.600" fontSize="sm" mb={6}>
            AI responses may be inaccurate or incomplete, so verify them before
            use. They assist in the hiring process, but should not be used to
            make hiring decisions.
          </Text>
          <FormControl mb="4">
            <FormLabel fontWeight="semibold">Template name</FormLabel>
            <Input
              placeholder="E.g. Screening Call"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              fontSize="sm"
              autoFocus
            />
          </FormControl>

          <FormControl mt={1} mb={8}>
            <FormLabel color="gray.800" fontWeight="semibold" mb={0}>
              Template type
            </FormLabel>
            <RadioGroup
              value={customType}
              onChange={(e) => setCustomType(e as CustomTemplateCustomType)}
              fontSize="sm"
              size="prismMd"
            >
              <Flex flexDir="column">
                <Radio
                  key={CustomTemplateCustomType.Sections}
                  value={CustomTemplateCustomType.Sections}
                  size="prismMd"
                >
                  <Text as="span" fontWeight="medium">
                    Basic:
                  </Text>{" "}
                  Define sections and what to include in them.
                </Radio>
                <Radio
                  key={CustomTemplateCustomType.CustomPrompt}
                  value={CustomTemplateCustomType.CustomPrompt}
                  size="prismMd"
                >
                  <Text as="span" fontWeight="medium">
                    Advanced:
                  </Text>{" "}
                  Directly author the AI prompt.
                </Radio>
              </Flex>
            </RadioGroup>
          </FormControl>

          {customType === "SECTIONS" && (
            <SectionsForm
              sections={sections}
              updateSection={updateSection}
              onDeleteSection={onDeleteSection}
              onAddSection={onAddSection}
              handleMove={handleMove}
              headerFontSize="sm"
            />
          )}

          {customType === "CUSTOM_PROMPT" && (
            <FormControl
              mt={0}
              mb="4"
              flexGrow="1"
              display="flex"
              flexDir="column"
            >
              <FormLabel fontWeight="semibold">AI Prompt</FormLabel>
              <Textarea
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                fontSize="sm"
                flexGrow="1"
                resize="none"
              />
            </FormControl>
          )}
        </TemplateFormColumn>
        <TemplateFormColumn
          flex="2"
          pl={[0, 0, 8, 8]}
          pr={[0, 0, 8, 8]}
          pt={[0, 0, 8, 8]}
          borderLeft={["0", "0", "1px solid", "1px solid"]}
          borderColor={["gray.200", "gray.200", "gray.200", "gray.200"]}
          overflowY={["inherit", "inherit", "auto", "auto"]}
        >
          {currentUserCanManageTemplates && (
            <>
              <RadioSection
                formLabel="Visibility"
                value={visibility}
                setValue={setVisibility}
                isDisabled={!canManageVisibility}
                options={[
                  {
                    value: CallAiSummaryTemplateVisibility.Private,
                    preLabel: "Private",
                    label: `Only the author (${
                      !template || template?.creator.email === currentUser.email
                        ? "you"
                        : template?.creator.email
                    }) can view and edit this template.`,
                  },
                  // TODO: Generate Public label dynamically based on org permissions
                  {
                    value: CallAiSummaryTemplateVisibility.Public,
                    preLabel: "Organization",
                    label:
                      "All org users can view and use this template. Users with template management permissions will also be able to edit it.",
                  },
                ]}
              />
              {visibility === CallAiSummaryTemplateVisibility.Public && (
                <FormControl mt={5}>
                  <TemplateFormLabel>
                    Assign template as default for others
                  </TemplateFormLabel>
                  <RadioGroup
                    value={defaultFor}
                    onChange={(e) =>
                      setDefaultFor(e as CallAiSummaryTemplateDefaultFor)
                    }
                    size="prismMd"
                  >
                    <Flex flexDir="column">
                      <Radio
                        value={CallAiSummaryTemplateDefaultFor.None}
                        size="prismMd"
                      >
                        No one else
                      </Radio>
                      <Radio
                        value={CallAiSummaryTemplateDefaultFor.All}
                        size="prismMd"
                      >
                        Everyone
                      </Radio>
                      <Flex>
                        <Radio
                          value={CallAiSummaryTemplateDefaultFor.Persona}
                          size="prismMd"
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            User groups
                          </span>
                        </Radio>
                        <Select
                          mb="2"
                          placeholder="Select user group"
                          isDisabled={
                            defaultFor !==
                            CallAiSummaryTemplateDefaultFor.Persona
                          }
                          value={defaultForPersona || undefined}
                          onChange={(e) =>
                            setDefaultForPersona(e.target.value as UserPersona)
                          }
                          ml={2}
                          fontSize="sm"
                        >
                          <option value={UserPersona.Interviewer}>
                            Interviewer
                          </option>
                          <option value={UserPersona.Recruiter}>
                            Recruiter
                          </option>
                          <option value={UserPersona.HiringManager}>
                            Hiring Manager
                          </option>
                          <option value={UserPersona.TaLeader}>
                            TA Leader
                          </option>
                          <option value={UserPersona.CoordinatorOps}>
                            Coordinator Ops
                          </option>
                        </Select>
                      </Flex>
                      <Flex>
                        <Radio
                          value={CallAiSummaryTemplateDefaultFor.MeetingType}
                          size="prismMd"
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            Meeting type
                          </span>
                        </Radio>
                        <Select
                          mb="2"
                          placeholder="Select meeting type"
                          isDisabled={
                            defaultFor !==
                            CallAiSummaryTemplateDefaultFor.MeetingType
                          }
                          value={defaultForMeetingType}
                          onChange={(e) =>
                            setDefaultForMeetingType(
                              e.target.value as CustomTemplateType
                            )
                          }
                          ml={2}
                          fontSize="sm"
                        >
                          <option value={CustomTemplateType.Interview}>
                            Interview
                          </option>
                          <option value={CustomTemplateType.Debrief}>
                            Debrief
                          </option>
                          <option value={CustomTemplateType.Intake}>
                            Intake
                          </option>
                        </Select>
                        <InfoIcon />
                      </Flex>
                      <Radio
                        value={CallAiSummaryTemplateDefaultFor.Keywords}
                        size="prismMd"
                      >
                        Meetings with any of the following keywords in title:
                      </Radio>
                      {/* Input for keywords */}
                      <Box pl={10}>
                        <Input
                          placeholder="Enter keywords, separated by commas"
                          isDisabled={
                            defaultFor !==
                            CallAiSummaryTemplateDefaultFor.Keywords
                          }
                          value={defaultForKeywords}
                          onChange={(e) => {
                            setDefaultForKeywords(e.target.value);
                          }}
                          fontSize="sm"
                        />
                      </Box>
                    </Flex>
                  </RadioGroup>
                </FormControl>
              )}
              <Divider my={6} />
            </>
          )}
          <TemplateFormLabel>User Preferences</TemplateFormLabel>
          <Switch
            isChecked={isCurrentUserDefault}
            onChange={(e) => setIsCurrentUserDefault(e.target.checked)}
            colorScheme="prism"
            size="sm"
            mb={4}
            fontSize="sm"
            lineHeight={1.5}
          >
            Make this my default template
          </Switch>
          <InfoText>
            Turn this setting on to automatically apply this template to your AI
            notes when you view a meeting. You will still be able to select
            other templates on the page.
          </InfoText>
        </TemplateFormColumn>
      </TemplateFormContent>
    </Box>
  );
};

const TemplateFormContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const height = [
    "auto",
    "auto",
    `calc(100vh - ${pageHeaderHeight})`,
    `calc(100vh - ${pageHeaderHeight})`,
  ];

  return (
    <Flex
      overflowY="auto"
      height={height}
      flex="1"
      flexDir={["column", "column", "row", "row"]}
      pb={[8, 8, 0, 0]}
    >
      {children}
    </Flex>
  );
};

const TemplateFormColumn: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      pt={8}
      pb={4}
      height="100%"
      overflowY="auto"
      flex="1"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

const TemplateFormLabel: React.FC<{
  tooltipLabel?: string;
  children: React.ReactNode;
}> = ({ tooltipLabel, children }) => (
  <Tooltip label={tooltipLabel} placement="right" isDisabled={!tooltipLabel}>
    <FormLabel
      color="gray.600"
      fontWeight="600"
      textTransform="uppercase"
      fontSize="xs"
    >
      {children}
    </FormLabel>
  </Tooltip>
);

const RadioSection: React.FC<{
  value: string;
  setValue: (value: any) => void;
  options: { value: any; label: string; preLabel?: string }[];
  tooltipLabel?: string;
  formLabel: string;
  isDisabled?: boolean;
}> = ({ value, setValue, options, tooltipLabel, formLabel, isDisabled }) => {
  return (
    <FormControl mt={6} isDisabled={isDisabled}>
      <TemplateFormLabel tooltipLabel={tooltipLabel}>
        {formLabel}
      </TemplateFormLabel>
      <RadioGroup value={value} onChange={(v) => setValue(v)} fontSize="sm">
        <Flex flexDir="column">
          {options.map((option) => (
            <Radio
              key={option.value}
              value={option.value}
              size="prismMd"
              alignItems="flex-start"
            >
              {option.preLabel && (
                <Text as="span" fontWeight="medium">
                  {`${option.preLabel}: `}
                </Text>
              )}
              {option.label}
            </Radio>
          ))}
        </Flex>
      </RadioGroup>
    </FormControl>
  );
};

const InfoText: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Text fontSize="sm" color="gray.600" fontWeight="400" mb="4">
    {children}
  </Text>
);

const InfoIcon = (): JSX.Element => (
  <Flex ml="2">
    <Tooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="sm"
      shouldWrapChildren
      fontWeight="normal"
      placement="left"
      label={
        <>
          Add intake@brighthire.ai or debrief@brighthire.ai to the calendar
          event as an attendee to designate the meeting type.
        </>
      }
    >
      <Icon boxSize={4} color="gray.700" as={HiOutlineInformationCircle} />
    </Tooltip>
  </Flex>
);

export default EditCustomTemplate;
