import { Box } from "@chakra-ui/react";
import React from "react";
import ReactMarkdown from "react-markdown";

type MarkdownOutputProps = {
  markdown: string;
  onClickTimestamp: (timestamp: number) => void;
};

const MarkdownOutput: React.FC<MarkdownOutputProps> = ({
  markdown,
  onClickTimestamp,
}) => {
  return (
    <Box
      mb={4}
      fontSize="sm"
      px={4}
      py="10px"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="lg"
      color="var(--chakra-colors-gray-700)"
      sx={{
        /* Apply margin-top to all headings */
        "& h1, & h2, & h3, & h4, & h5, & h6": {
          marginTop: "1rem",
          fontWeight: "600",
          mb: "0.25rem",
        },
        "& h1": {
          fontSize: "var(--chakra-fontSizes-lg)",
        },
        "& h2": {
          fontSize: "var(--chakra-fontSizes-md)",
        },
        "& h3, & h4, & h5, & h6": {
          fontSize: "var(--chakra-fontSizes-sm)",
        },
        "& p": {
          margin: "0",
          mb: "0.5rem",
        },
        "& hr": {
          display: "block",
          height: "1px",
          border: "0",
          borderTop: "1px solid #ccc",
          margin: "1em 0",
          padding: "0",
        },
        "& li": {
          margin: "0",
          mb: "0.25rem",
        },
        "& ul, & ol": {
          marginLeft: "1rem",
        },
        "& a": {
          color: "var(--chakra-colors-blue-600)",
        },
        /* But if an element is the first child, reset margin-top */
        "& :first-of-type": {
          marginTop: 0,
        },
      }}
    >
      <ReactMarkdown
        components={{
          a(props) {
            const { node, ...rest } = props;
            const href = node.properties?.href || "";
            if (!(typeof href === "string")) {
              return null;
            }
            // Handle internal links to timestamps in the current interview
            if (href.startsWith(`?t=`)) {
              const seconds = parseInt(href.split("?t=")[1]) || 0;
              return (
                <a
                  onClick={(e) => {
                    onClickTimestamp(seconds);
                    e.preventDefault();

                    // Update URL to new timestamp, keeping old params
                    const currentParams = new URLSearchParams(
                      window.location.search
                    );
                    currentParams.set("t", seconds.toString());
                    const newUrl = `${
                      window.location.pathname
                    }?${currentParams.toString()}`;
                    window.history.replaceState({}, "", newUrl);
                  }}
                  {...rest}
                />
              );
            }

            // Otherwise link to new tab
            return <a target="_blank" {...rest} />;
          },
        }}
      >
        {markdown}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownOutput;
