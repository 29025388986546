import {
  Box,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useCallback, useMemo } from "react";
import { HiOutlinePencil, HiOutlinePlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Select, {
  components,
  OptionProps,
  SingleValueProps,
} from "react-select";

import { Button, IconButton, useTheme } from "../../../../../../components";
import useSelectTheme from "../../../../../../hooks/useSelectTheme";
import { useSendGAEvent } from "../../../../../../utils/googleAnalytics";
import {
  CallAiSummaryFormat,
  CustomTemplateCustomType,
  useCreateAiSummaryTemplateMutation,
  useCurrentUserAiSummaryTemplatesQuery,
  useDeleteAiSummaryTemplateMutation,
  useUpdateAiSummaryTemplateMutation,
  useUpdateCallAiSummaryFormatMutation,
} from "../../../../../graphql";
import useFeatureFlag from "../../../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import { CustomTemplateInput } from "../../../../CallTemplates/types";
import EditCustomTemplateModal from "./EditCustomTemplateModal";
import { NotesFormatLabels } from "./NotesFormatLabels";

type Option = {
  label: string;
  value: CallAiSummaryFormat | "create";
  templateId?: string | null;
};

type GroupedOption = {
  label: string;
  options: Option[];
};

const formatToOption = (
  format: CallAiSummaryFormat,
  customTemplateId?: string | null
): Option => {
  return {
    label: NotesFormatLabels[format],
    value: format,
    templateId: customTemplateId,
  };
};

const formatGroupLabel = (data: GroupedOption): React.ReactNode | null => {
  if (!data.label) return null;
  return (
    <Box
      borderTop="1px solid"
      borderColor="gray.200"
      px="3"
      mt="0"
      pt="3"
      mx="-3"
    >
      {data.label}
    </Box>
  );
};

const Option: React.FC<OptionProps<Option, false, GroupedOption>> = ({
  children,
  ...props
}) => {
  return (
    <span
      data-tour-id={
        props.label?.toLowerCase &&
        `ai-notes-format-${props.label.toLowerCase().replace(/\s/g, "-")}`
      }
    >
      <components.Option {...props}>{children}</components.Option>
    </span>
  );
};

const SingleValue: React.FC<SingleValueProps<Option, false, GroupedOption>> = ({
  children,
  ...props
}) => {
  return <components.SingleValue {...props}>{children}</components.SingleValue>;
};

export type FormatSelectorProps = {
  availableFormats: CallAiSummaryFormat[];
  format: CallAiSummaryFormat;
  customTemplateId?: string | null;
  switchFormat: (
    format: CallAiSummaryFormat,
    customTemplateId?: string | null,
    forceRegenerate?: boolean
  ) => void;
  callId: string;
  onRetry?: () => void;
};

const FormatSelector: React.FC<FormatSelectorProps> = ({
  availableFormats,
  format,
  customTemplateId,
  switchFormat,
  callId,
  onRetry,
}) => {
  const chakraTheme = useTheme();
  const templateModal = useDisclosure();
  const useNewTemplateEditor = useFeatureFlag("custom_templates_v2:launch");
  const displaySharedTemplates = useFeatureFlag("custom_templates_v2:launch");
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const customTemplatesEnabled =
    currentUser.organization.allowAiCustomTemplates;
  const [isNewTemplate, setIsNewTemplate] = React.useState<boolean>(false);

  // Additional template ID is used to link to shared templates
  // this can be removed once org custom templates are implemented
  const { data: customTemplateData, refetch: refetchCustomTemplates } =
    useCurrentUserAiSummaryTemplatesQuery({
      variables: {
        additionalTemplateId: customTemplateId,
      },
      fetchPolicy: "cache-and-network",
    });
  const customTemplates =
    customTemplateData?.currentUser?.callAiSummaryTemplates;

  const [generateSummary] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  const [createTemplateMutation] = useCreateAiSummaryTemplateMutation();
  const createTemplate = (input: CustomTemplateInput): void => {
    sendGAEvent(
      "ai_notes_custom_template_create_finish",
      "call_review",
      undefined,
      undefined,
      {
        callId,
      }
    );
    const sectionsWithoutIds = input.sections.map((section) => ({
      title: section.title,
      prompt: section.prompt,
    }));
    createTemplateMutation({
      variables: {
        name: input.name,
        isCreatorDefault: input.isCreatorDefault,
        sections: sectionsWithoutIds,
        customType: input.customType || CustomTemplateCustomType.Sections,
        customPrompt: input.customPrompt,
        model: input.model,
        visibility: input.visibility,
        isCurrentUserDefault: input.isCurrentUserDefault,
        defaultFor: input.defaultFor,
        defaultForPersona: input.defaultForPersona,
        defaultForKeywords: input.defaultForKeywords,
        defaultForMeetingType: input.defaultForMeetingType,
      },
      onCompleted: (data) => {
        if (data.createAiSummaryTemplate?.template?.id) {
          toast({
            status: "success",
            description: "Template created",
            position: "top",
          });
          templateModal.onClose();
          setIsNewTemplate(false);
          refetchCustomTemplates();
          switchFormat(
            CallAiSummaryFormat.Custom,
            data?.createAiSummaryTemplate?.template?.id,
            true
          );
        }
      },
    });
  };

  const [updateTemplateMutation] = useUpdateAiSummaryTemplateMutation();
  const updateTemplate = (input: CustomTemplateInput): void => {
    const sectionsWithoutIds = input.sections.map((section) => ({
      title: section.title,
      prompt: section.prompt,
    }));
    if (!input.id) return;
    updateTemplateMutation({
      variables: {
        id: input.id,
        name: input.name,
        isCreatorDefault: input.isCreatorDefault,
        sections: sectionsWithoutIds,
        customType: input.customType || CustomTemplateCustomType.Sections,
        customPrompt: input.customPrompt,
        model: input.model,
        visibility: input.visibility,
        isCurrentUserDefault: input.isCurrentUserDefault,
        defaultFor: input.defaultFor,
        defaultForPersona: input.defaultForPersona,
        defaultForKeywords: input.defaultForKeywords,
        defaultForMeetingType: input.defaultForMeetingType,
      },
      onCompleted: (data) => {
        if (data.updateAiSummaryTemplate?.template?.id) {
          toast({
            status: "success",
            description: "Template updated",
            position: "top",
          });
          templateModal.onClose();
          refetchCustomTemplates();
          // Needed to retry fetch after generating mutation returns to trigger
          // loading screen since the ID and format do not change
          generateSummary({
            variables: {
              callId,
              format,
              customTemplateId,
              targetSpeakerTags: [],
              customTopics: [],
              forceRegenerate: true,
            },
          }).then(() => {
            if (onRetry) {
              onRetry();
            }
          });
        }
      },
    });
  };

  const [deleteTemplateMutation] = useDeleteAiSummaryTemplateMutation();
  const deleteTemplate = (id: string): void => {
    deleteTemplateMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        toast({
          status: "success",
          description: "Template deleted",
          position: "top",
        });
        switchFormat(CallAiSummaryFormat.Qanda, null, false);
        refetchCustomTemplates();
      },
    });
  };

  const formatOptionLabel = useCallback(
    (data: Option): React.ReactNode => {
      if (data.value === "create")
        return (
          <Flex color="blue.600" alignItems="center">
            <Box as={HiOutlinePlus} size="18px" mr="2" />
            <Text fontWeight="500">Create template</Text>
          </Flex>
        );
      if (data.templateId) {
        const template = customTemplates?.find((t) => t.id === data.templateId);
        if (template) {
          return template.name.trim() !== "" ? template.name : "Untitled";
        }
      }
      return `${data.label}`;
    },
    [customTemplates]
  );

  const [theme, styles] = useSelectTheme({
    control: (base) => ({
      ...base,
      minHeight: "unset",
      lineHeight: "20px",
      border: `1.5px solid ${chakraTheme.colors.gray[200]}`,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "6px",
    }),
    container: (base) => ({
      ...base,
      width: "100%",
      fontSize: "0.875rem",
      fontWeight: "400",
      borderRadius: "6px",
    }),
    group: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: "4px",
    }),
  });

  let options: GroupedOption[] = [
    {
      label: "",
      options: availableFormats.map((format) => formatToOption(format, null)),
    },
  ];
  if (customTemplatesEnabled) {
    const customOptions =
      customTemplates?.map((template) => ({
        label: template.name,
        value: CallAiSummaryFormat.Custom,
        templateId: template.id,
        createdBy: template.createdBy,
      })) || [];

    const basicFormatOptionGroup = {
      label: "",
      options: availableFormats
        // CUSTOM format is not a format by itself. It is tied to a template ID
        .filter((format) => format !== CallAiSummaryFormat.Custom)
        .map((format) => formatToOption(format, null)),
    };

    if (displaySharedTemplates) {
      options = [
        basicFormatOptionGroup,
        {
          label: "My templates",
          options: customOptions.filter((o) => o.createdBy === currentUser.id),
        },
        {
          label: "Shared templates",
          options: [
            ...customOptions.filter((o) => o.createdBy !== currentUser.id),
            { label: "Create template", value: "create" },
          ],
        },
      ];
    } else {
      options = [
        basicFormatOptionGroup,
        {
          label: "My templates",
          options: [
            ...customOptions,
            { label: "Create template", value: "create" },
          ],
        },
      ];
    }
  }

  const templateForModal = useMemo(() => {
    return (
      customTemplates?.find((template) => template.id === customTemplateId) ??
      null
    );
  }, [customTemplateId, customTemplates]);

  const onSelectCreateTemplate = useCallback((): void => {
    LogRocket.track(`ai-notes-format-create-start`);
    sendGAEvent(
      "ai_notes_custom_template_create_start",
      "call_review",
      undefined,
      undefined,
      {
        callId,
      }
    );
    if (useNewTemplateEditor) {
      navigate(`/template/new?callId=${callId}`);
    } else {
      setIsNewTemplate(true);
      templateModal.onOpen();
    }
  }, [sendGAEvent, templateModal, callId, useNewTemplateEditor, navigate]);

  const showEditButton =
    customTemplateId && templateForModal?.canEdit && customTemplatesEnabled;

  return (
    <>
      {templateModal.isOpen && !useNewTemplateEditor && (
        <EditCustomTemplateModal
          template={isNewTemplate ? null : templateForModal}
          onClose={() => {
            templateModal.onClose();
            if (isNewTemplate) {
              setIsNewTemplate(false);
            }
          }}
          onDelete={deleteTemplate}
          onSave={(input) =>
            input.id ? updateTemplate(input) : createTemplate(input)
          }
        />
      )}
      <Flex alignItems="center" flexGrow="1">
        <Box
          data-tour-id="ai-notes-format-selector"
          flexGrow="1"
          maxWidth="350px"
        >
          <Select<Option, false, GroupedOption>
            theme={theme}
            styles={styles}
            isSearchable={false}
            components={{
              Option,
              SingleValue,
            }}
            options={options}
            value={formatToOption(format, customTemplateId)}
            onChange={(newVal) => {
              if (newVal?.value) {
                if (newVal?.value === "create") {
                  onSelectCreateTemplate();
                } else {
                  switchFormat(newVal.value, newVal.templateId);
                }
              }
            }}
            isOptionSelected={(option) =>
              option.value === format && option.templateId === customTemplateId
            }
            formatGroupLabel={formatGroupLabel}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getOptionLabel={formatOptionLabel}
          />
        </Box>
        {showEditButton && (
          <Tooltip label="Edit">
            <IconButton
              ml="2"
              size="sm"
              icon={<HiOutlinePencil size="16px" />}
              variant="icon"
              aria-label="Edit"
              bhEvent={{
                category: "call_review",
                action: "ai_notes_custom_template_edit",
              }}
              onClick={
                useNewTemplateEditor
                  ? () =>
                      navigate(`/template/${customTemplateId}?callId=${callId}`)
                  : templateModal.onOpen
              }
            />
          </Tooltip>
        )}
        {useNewTemplateEditor && customTemplatesEnabled && (
          <Button
            ml={2}
            mr={4}
            size="prismMdLight"
            variant="prismEmphasis"
            colorScheme="blue"
            leftIcon={<HiOutlinePlus />}
            onClick={onSelectCreateTemplate}
          >
            New template
          </Button>
        )}
        {/* Spacer for when edit button is hidden. This allows for the selector
        to expand to fill empty space, but not use up missing space from the edit */}
        {!showEditButton && <Box width="40px" />}
      </Flex>
    </>
  );
};

export default FormatSelector;
