import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { IconButton } from "../../../components";

const EditTemplateButton: React.FC<{
  templateId: string;
}> = ({ templateId }) => {
  const navigate = useNavigate();
  return (
    <Tooltip label="Edit template">
      <IconButton
        aria-label="Edit template"
        colorScheme="blackAlpha"
        color="gray.900"
        icon={<HiOutlinePencil />}
        onClick={() => {
          navigate(`/template/${templateId}`);
        }}
      />
    </Tooltip>
  );
};

export default EditTemplateButton;
