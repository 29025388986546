import React from "react";

import { CallAiSummaryTemplateFragment } from "../../graphql";
import EditCustomTemplate from "./EditCustomTemplate";
import useCustomTemplate from "./useCustomTemplate";

type CustomTemplateProps = {
  template: CallAiSummaryTemplateFragment | null;
  fromCallId: string | null;
};

const CustomTemplate: React.FC<CustomTemplateProps> = ({
  template,
  fromCallId,
}) => {
  const { createCustomTemplate, updateCustomTemplate, navigateBack } =
    useCustomTemplate({ fromCallId });

  return (
    <EditCustomTemplate
      template={template}
      onClose={() => navigateBack(template?.id)}
      onSave={template ? updateCustomTemplate : createCustomTemplate}
    />
  );
};

export default CustomTemplate;
