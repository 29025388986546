import {
  Box,
  ButtonGroup,
  Flex,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {
  HiDotsVertical,
  HiOutlineChatAlt2,
  HiOutlineDuplicate,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { IconButton, MenuButton } from "../../../components";
import {
  CallAiSummaryTemplateListItemFragment,
  useDuplicateAiSummaryTemplateMutation,
} from "../../graphql";
import DeleteTemplateMenuItem from "./DeleteTemplateMenuItem";
import EditTemplateButton from "./EditTemplateButton";

type CallAiSummaryTemplateActionsProps = {
  template: CallAiSummaryTemplateListItemFragment;
};

const CallAiSummaryTemplateActions: React.FC<
  CallAiSummaryTemplateActionsProps
> = ({ template }) => {
  // TODO: Discussed during requirements is to open the edit modal for a new
  // template when it is copied. This is not implemented yet.

  const toast = useToast();
  const navigate = useNavigate();

  const [copyTemplate] = useDuplicateAiSummaryTemplateMutation({
    // Using refetch instead of cache update to avoid complexity of sorting
    refetchQueries: ["CurrentUserMyCallSummaryTemplates"],
    onCompleted() {
      toast({
        title: "Template copied to My Templates",
        status: "success",
      });
    },
  });

  return (
    <Flex width="100%" alignItems="center" justifyContent="end">
      <ButtonGroup variant="ghost" size="md" ml="auto">
        {template.canEdit && <EditTemplateButton templateId={template.id} />}
        <Tooltip label="Copy template">
          <IconButton
            aria-label="Copy template"
            colorScheme="blackAlpha"
            color="gray.900"
            icon={<HiOutlineDuplicate size="20px" />}
            onClick={() => {
              copyTemplate({ variables: { id: template.id } });
            }}
          />
        </Tooltip>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HiDotsVertical />}
            colorScheme="blackAlpha"
            color="gray.900"
          />
          <MenuList>
            <MenuItem
              value="example"
              onClick={() => {
                navigate(`/search?template_id=${template.id}`);
              }}
            >
              <HiOutlineChatAlt2 size="20px" />
              <Box flex="1" ml="2">
                Go to example interview
              </Box>
            </MenuItem>
            {template.canDelete && (
              <>
                <MenuDivider />
                <DeleteTemplateMenuItem templateId={template.id} />
              </>
            )}
          </MenuList>
        </Menu>
      </ButtonGroup>
    </Flex>
  );
};

export default CallAiSummaryTemplateActions;
