import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { SortingRule } from "react-table";

import {
  EmptyAIIcon,
  RouterLink,
  SortableTable,
  SortableTableColumn,
} from "../../../components";
import { formatRelativeDate } from "../../../utils/datetime";
import { snakeCaseToTitleCase } from "../../../utils/string";
import { PageOptions } from "../../../utils/types";
import {
  CallAiSummaryTemplateDefaultFor,
  CallAiSummaryTemplateListItemFragment,
} from "../../graphql";
import CreateTemplateButton from "../../pages/templates/CreateTemplateButton";
import CallAiSummaryTemplateActions from "./CallAiSummaryTemplateActions";

type ListTemplate = CallAiSummaryTemplateListItemFragment;

interface CallAiSummaryTemplateListProps {
  templates: ListTemplate[];
  pageOptions?: PageOptions;
  loading?: boolean;
  sortBy: SortingRule<string>;
}

const CallAiSummaryTemplateList: React.FC<CallAiSummaryTemplateListProps> = ({
  templates,
  pageOptions,
  loading,
  sortBy,
}) => {
  const columns: Array<SortableTableColumn<ListTemplate>> = [
    {
      Header: "Template Name", // TODO: resolve whether title case
      accessor: "name",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => (
        <RouterLink to={`/template/${template.id}`}>
          {template.name || (
            <Box as="span" color="gray.400">
              Untitled
            </Box>
          )}
        </RouterLink>
      ),
    },
    {
      Header: "Author",
      accessor: "creator",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => <Box whiteSpace="nowrap">{template.creator.fullName}</Box>,
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => (
        <Box whiteSpace="nowrap">{formatRelativeDate(template.createdAt)}</Box>
      ),
    },
    {
      Header: "Visibility",
      accessor: "visibility",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => (
        <Box whiteSpace="nowrap">
          {template.visibility === "PUBLIC" ? "Organization" : "Private"}
        </Box>
      ),
    },
    {
      Header: "Default Assignment",
      accessor: "defaultFor",
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => {
        const keywords = template.defaultForKeywords || [];
        if (keywords.length > 4) {
          keywords.splice(4);
          keywords.push("...");
        }
        const keywordString = keywords.join(", ");

        return (
          <Box whiteSpace="nowrap">
            {template.defaultFor === CallAiSummaryTemplateDefaultFor.All ? (
              "All"
            ) : template.defaultFor === CallAiSummaryTemplateDefaultFor.None ? (
              "None"
            ) : template.defaultFor ===
              CallAiSummaryTemplateDefaultFor.Persona ? (
              <>{`User group: ${snakeCaseToTitleCase(
                (template.defaultForPersona as string) || ""
              )}`}</>
            ) : template.defaultFor ===
              CallAiSummaryTemplateDefaultFor.Keywords ? (
              <>{`Keywords: ${keywordString}`}</>
            ) : template.defaultFor ===
              CallAiSummaryTemplateDefaultFor.MeetingType ? (
              <>{`Meeting type: ${snakeCaseToTitleCase(
                (template.defaultForMeetingType as string) || ""
              )}`}</>
            ) : (
              "Unknown"
            )}
          </Box>
        );
      },
    },
    {
      Header: " ", // Empty header for the menu (must be a space)
      Cell: ({
        row: { original: template },
      }: {
        row: { original: ListTemplate };
      }) => <CallAiSummaryTemplateActions template={template} />,
    },
  ];

  const isEmpty = templates.length === 0 && !loading;

  return (
    <Box mb="8">
      <SortableTable
        columns={columns}
        data={templates}
        manualPagination
        pageOptions={isEmpty ? undefined : pageOptions}
        width="100%"
        initialSort={sortBy}
        loading={loading}
      />
      {isEmpty && (
        <Box textAlign="center" py="6">
          <EmptyAIIcon />
          <Text mt="4" mb="6" fontWeight="600" fontSize="lg">
            No templates to display yet. Build one today!
          </Text>
          <CreateTemplateButton />
        </Box>
      )}
    </Box>
  );
};

export default CallAiSummaryTemplateList;
